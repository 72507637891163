body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-pdf__Page__svg {
  margin: auto;
  width: 100% !important;
  height: auto !important;
  max-width: 600px;
}

.react-pdf__Page__svg svg {
  width: inherit !important;
  height: inherit !important;
}

/* cookie styles */

.cookiebot-change-consent {
  display: none;
  height: 4.375rem;
  width: 4.375rem;
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  background-color: #52ae30;
  border-radius: 100%;
  backface-visibility: hidden;
  z-index: 999;
}

@media (max-width: 61.9em) {
  .cookiebot-change-consent {
    height: 3.125rem;
    width: 3.125rem;
    left: 0.5rem;
    bottom: 0.5rem;
  }
}

.cookiebot-change-consent a {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 100%;
}

.cookiebot-change-consent a:before {
  content: "";
  height: 1.875rem;
  width: 1.875rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iXzAxNSIgZGF0YS1uYW1lPSIwMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMwIiBoZWlnaHQ9IjMwIiB2aWV3Qm94PSIwIDAgMzAgMzAiPiAgPGRlZnM+ICAgIDxzdHlsZT4gICAgICAuY2xzLTEgeyAgICAgICAgZmlsbDogI2ZmZjsgICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsgICAgICB9ICAgIDwvc3R5bGU+ICA8L2RlZnM+ICA8ZyBpZD0iZ3JvdXAiPiAgICA8ZyBpZD0iZ3JvdXAtMiIgZGF0YS1uYW1lPSJncm91cCI+ICAgICAgPGcgaWQ9Imdyb3VwLTMiIGRhdGEtbmFtZT0iZ3JvdXAiPiAgICAgICAgPHBhdGggaWQ9IkNvbG9yX0ZpbGxfMSIgZGF0YS1uYW1lPSJDb2xvciBGaWxsIDEiIGNsYXNzPSJjbHMtMSIgZD0iTTYuOSwyMy4xQTExLjQ1OCwxMS40NTgsMCwwLDAsMjMuMTA1LDYuOUwyMy4xLDYuOUExMS40NTgsMTEuNDU4LDAsMCwwLDYuOSwyMy4xWk02LjA4OCw2LjA4OEw2LjksNi45bC0xLjA4LTEuMDhMNi45LDYuOVpNMjQuNzIzLDI0LjcyM2ExMy43NjksMTMuNzY5LDAsMSwxLDAtMTkuNDQ1LDEzLjc1LDEzLjc1LDAsMCwxLDAsMTkuNDQ1aDBaTTE1LDEwLjQxNkExLjE0NiwxLjE0NiwwLDEsMSwxNi4xNDYsOS4yNywxLjE0NiwxLjE0NiwwLDAsMSwxNSwxMC40MTZaTTE0LjE5LDIxLjUzOWExLjE0NiwxLjE0NiwwLDAsMS0uMzM2LTAuODFoMi4yOTJBMS4xNDYsMS4xNDYsMCwwLDEsMTQuMTksMjEuNTM5Wm0tMC4zMzYtNy42ODR2Ni44NzRoMi4yOTJWMTMuODU0YTEuMTQ2LDEuMTQ2LDAsMCwwLTIuMjkyLDBoMFoiLz4gICAgICA8L2c+ICAgIDwvZz4gIDwvZz48L3N2Zz4=);
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 61.9em) {
  .cookiebot-change-consent a:before {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.sr {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

#CybotCookiebotDialog .cookie-popup__close {
  height: 1.5rem !important;
  width: 1.5rem !important;
  top: 22px !important;
  left: 10px !important;
  cursor: pointer !important;
}


@media (max-width: 37.9em) {
  #CybotCookiebotDialog .cookie-popup__close {
    top: 1rem !important;
    left: 2px !important;
  }
}

#CybotCookiebotDialog .cookie-popup__close-icon {
  width: 100% !important;
  opacity: .5 !important;
  height: 100% !important;
}

/* Hide only the redundant elements */
.cookie-cookiebot-declaration {
  display: none;
}

/* end cookie styles */
.w-50 {
  width: 50%;
}

.justify-between {
  justify-content: space-between;
}

.flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.items-center {
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.m-auto {
  margin: auto;
}